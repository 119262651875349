import React, { SyntheticEvent } from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as LipsSVG } from '../../../../assets/images/landing/svg/pattern-footer.svg';
import LogoIcon from '../../../../assets/images/icons/common/logo.png';
import LogoText from '../../../../assets/images/icons/common/logo-text.png';
import { Terms } from '../../../../components/terms';
import { useModal } from '@/hooks/use-modal';
import { ModalUI } from '../../../../components/UI/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';




export const Footer = () => {
  const termModal = useModal();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const animationContainer = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
      },
    }),
  };

  const handleTermsInfo = (event: SyntheticEvent) => {
    event.preventDefault();
    termModal.openModal();
  };


  return (
    <motion.footer
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.3, once: true }}
      style={{
        boxShadow: '0px -4px 10px 11px rgba(252,241,234,1)',
        background:
          'linear-gradient(180deg, rgba(252,241,234,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%)',
      }}
      className='bg-white pt-40 pb-8 relative z-10 overflow-hidden'
    >
      <div className={isMobile ? 'absolute -top-[40px] left-[50%] -translate-x-[50%] -translate-y-80  lg:-translate-y-52 -z-10' : 'absolute -top-[80px] left-[50%] -translate-x-[50%] -translate-y-80  lg:-translate-y-52 -z-10'}>
        <LipsSVG className='max-w-[440px] lg:max-w-full' />
      </div>
      <Container className='md:px-28 xl:px-2 lg:max-w-6xl mx-auto'>
        <div className='flex flex-col lg:flex-row items-center lg:items-end gap-x-2 lg:mb-2'>
          <div className='mb-3'>
            <img
              className='h-24 w-[64px] lg:w-[32px] lg:h-[48px] object-contain'
              src={LogoIcon}
              alt='logo'
            />
          </div>

          <div className={'w-[134px] h-[19px] mb-6'}>
            <img className='h-auto max-w-full object-contain' src={LogoText} alt='two-in-town' />
          </div>
        </div>

        <div className='flex flex-col lg:flex-row items-center lg:items-stretch gap-x-8 text-stylish-black mb-20 font-averta'>
          <div className='grow mb-8 lg:mb-0'>
            { isMobile ? <></> : 
            <p className='max-w-[240px] text-stylish-black font-averta leading-6 text-center lg:text-left'>
              Crafting personalized gold and silver nameplates that celebrate your uniqueness
            </p>
            }
          </div>
          <div className='flex flex-col justify-between mb-6 lg:mb-0'>
            <a
              className='text-center lg:text-left text-stylish-black no-underline font-averta text-lg font-bold'
              href='tel:+12122780070'
            >
              (212) 278-0070
            </a>
            <a
              className='text-stylish-black no-underline font-averta'
              href='mailto:support@twointown.com'
            >
              support@twointown.com
            </a>
          </div>
          <div>
            <p className='leading-6 text-center lg:text-left'>
              71 W 47th Street, <br />
              Suite 502, New York, <br />
              NY 10036
            </p>
          </div><br />
          {isMobile ? <div className='px-5 bg-stylish-black opacity-30' style={{ height: 1, width: '80%' }}></div> : <></>}
          <div>
            <p className={isMobile ? 'leading-4 text-center lg:text-left pb-3 pt-3' : 'leading-6 text-center lg:text-left'}>
              {isMobile ? <br /> : <></>}
              <a className='underline' onClick={handleTermsInfo}>Privacy Policy</a>{isMobile ? <br /> : <></>}<br />
              <a className='underline'>Terms of Service</a>{isMobile ? <br /> : <></>}<br />
              <a className='underline'>Shipping and Returns</a>{isMobile ? <br /> : <></>}
              <br />
            </p>
          </div>
          {isMobile ? <div className='px-5 bg-stylish-black opacity-30' style={{ height: 1, width: '80%' }}></div> : <></>}
        </div>

        <p className={isMobile ? 'text-stylish-black/80 text-center lg:text-left -mt-[60px] text-[13px] font-light' : 'text-[13px] font-light text-stylish-black/80 text-center lg:text-left'}>
          ©2022-{new Date().getFullYear()} Two in town. All right reserved
        </p>
      </Container>
      <ModalUI
        title={
          <Box component='p'>
            Terms of Service <br /> & Privacy Policy <br /> for Two in Town, Inc
          </Box>
        }
        isOpen={termModal.isOpen}
        onClose={termModal.closeModal}
        renderButtons={
          <>
            {/* <Button
              onClick={handleDeclineTerms}
              sx={{
                '&:hover': {
                  border: '2px solid  #3E4966',
                },
                border: '2px solid  #3E4966',
                borderRadius: '16px',
                color: '#3E4966',
                maxWidth: '214px',
                maxHeight: '64px',
                width: '100%',
                fontSize: '21px',
                px: '28px',
                py: '21px',
                textTransform: 'none',
              }}
              variant='outlined'
            >
              Decline
            </Button>
            <Button
              onClick={handleAcceptTerms}
              sx={{
                borderRadius: '16px',
                color: '#FFFFFF',
                maxWidth: '214px',
                maxHeight: '64px',
                width: '100%',
                px: '28px',
                py: '21px',
                fontSize: '21px',
                backgroundColor: '#3E4966',
                textTransform: 'none',
                '&:hover, &:active': {
                  backgroundColor: '#3E4966',
                },
              }}
              variant='contained'
            >
              Accept
            </Button> */}
          </>
        }
      >
        <Terms />
      </ModalUI>
    </motion.footer>
  );
};

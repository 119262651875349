import React from 'react';
import cls from 'classnames';


import { useNameplateSettings } from '../../../../../hooks';
import { NameplateMaterialType } from '../../../../../hooks';

import { ReactComponent as GoldMaterialIcon } from '../../../../../assets/images/icons/materials/Gold.svg';
import { ReactComponent as RoseGoldMaterialIcon } from '../../../../../assets/images/icons/materials/Rose_gold.svg';
import { ReactComponent as SilverMaterialIcon } from '../../../../../assets/images/icons/materials/Silver.svg';

import classes from './MaterialMenu.module.css';
import { removeUnderscores } from '../../../../../utils/text';
import { useMediaQuery } from '@mui/material';

const materialIcons: { [index: string]: any } = {
  Gold: <GoldMaterialIcon />,
  Rose_gold: <RoseGoldMaterialIcon />,
  Silver: <SilverMaterialIcon />,
};

export const MaterialMenu: React.FC = () => {
  const { constructor, selectedMaterial, onSelectMaterial } = useNameplateSettings();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const isMaterialChosen = (material: string) =>
    selectedMaterial === (material as NameplateMaterialType);

  const handleChooseMaterial = (material: string) =>
    onSelectMaterial(material as NameplateMaterialType);

  const getTitle = (material: string) => {
    let res = removeUnderscores(material);
    if (res.toLowerCase() === 'rose gold') {
      res = 'White Gold';
    }
    return res;
  };

  return (
    <>
      <span className={cls(classes.selectedMaterial, 'z-20 self-center mt-1 mb-1.5', isMobile && 'mb-2')}>
        <span className={cls(isMobile && 'text-[21px]')}>Metal</span>
      </span>
      <div className='flex justify-center items-center pl-3 pr-3 pt-2 w-full h-[115px]'>
        {constructor.config.materials.map((material, i) => (
          <div
            key={i}
            className={cls(
              classes.materialItem,
              isMaterialChosen(material.title) && classes.materialItemActive,
            )}
            onClick={() => handleChooseMaterial(material.title)}
          >
            <div className={classes.materialItemImageContainer}>
              {materialIcons[material.title]}
            </div>
            <span
              className={cls(
                classes.materialItemTitle,
                isMaterialChosen(material.title) && classes.materialItemTitleActive,
                isMobile && classes.materialItemTitleMobile,
              )}
            >
              {getTitle(material.title)}
            </span>
            <span
              className={cls(
                classes.materialItemCarat,
                isMaterialChosen(material.title) && classes.materialItemTitleActive,
                isMobile && classes.materialItemTitleMobile,
              )}
            >
              {getTitle(material.carat)}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

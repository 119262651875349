import React, { useEffect, useState } from 'react';

import { AppMode, useCommonState, useNameplateSettings } from '@/hooks';
import { NameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/NameplateCanvasComponent';
import NameplateHeader from '../NameplateHeader';
import NameplateMenuActions from './NameplateMenuActions';
import cls from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Preloader } from '@/components/common/Preloader';
import Button from '@mui/material/Button';
import { ReactComponent as TutorialArrowIcon } from '@assets/images/cursor/tutorialArrow.svg'
import { ReactComponent as ZoomWithFingers } from '@assets/images/cursor/zoomWithFingers.svg'

export const mobilePanel = 'mobilePanel';
export const NameplateMobileSquare: React.FC = () => {
    const { texturesLoading, constructor, images } = useNameplateSettings();
    const { appMode } = useCommonState();
    const isLoading = texturesLoading || constructor.isLoading || !!images?.isLoading;

    const [tutorialStep, setTutorialStep] = useState<number>(0);

    return (
        <motion.div
            className={cls('w-full relative bg-cover bg-no-repeat bg-top bg-white', {
                'h-full': appMode === AppMode.CONSTRUCTOR,
            })}
        >
            <Preloader open={isLoading} />
            {
                tutorialStep <= 1 && appMode === AppMode.CONSTRUCTOR ?
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.4, delay: 1 }}
                        className='flex flex-col items-center'
                        style={{ backgroundColor: "rgba(15, 26, 52, .6)", width: '100%', height: '100%', position: 'absolute', zIndex: 1002 }}
                    >
                        {tutorialStep === 0 ?
                            <motion.div>
                                <motion.div
                                    initial={{ y: 16, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 0.5, delay: 1.15, opacity: { duration: 0.5 } }}
                                    className='flex flex-col items-center'
                                >
                                    <p style={{ lineHeight: '32px' }} className='text-center font-semibold text-[24px] pl-12 pr-12 pt-28'>depending on size,</p>
                                    <p style={{ lineHeight: '32px' }} className='text-center font-semibold text-[24px] pl-12 pr-12 pb-10'>price updates on flight</p>
                                </motion.div>
                                <motion.div
                                    className='flex flex-col items-center'
                                    initial={{ y: 16, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 0.5, delay: 1.25, opacity: { duration: 0.5 } }}
                                >
                                    <Button
                                        className={cls(
                                            'w-[132px] h-[56px] text-stylish-black text-normal font-semibold text-[21px] cursor-pointer whitespace-nowrap bg-white normal-case',
                                        )}
                                        style={{ borderRadius: 36 }}
                                        onClick={() => { setTutorialStep(1) }}
                                    >
                                        Got it
                                    </Button>
                                </motion.div>
                                <TutorialArrowIcon style={{ position: 'absolute', right: 20, top: 16 + 48 }} />
                            </motion.div>
                            :
                            <motion.div
                            initial="visible"
                            style={{opacity: 0, y: 16}}//{{ y: 16, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 0.5, delay: 0.45, opacity: { duration: 0.5 } }}
                                 
                            >
                                <motion.div
                                >
                                    <p className='text-center font-semibold text-[24px] pl-12 pr-12 pt-28 pb-52'>Zoom with fingers</p>
                                    <ZoomWithFingers style={{ position: 'absolute', top: 50 }} />
                                </motion.div>
                                <motion.div
                                    className='flex flex-col items-center'
                                >
                                    <Button
                                        className={cls(
                                            'w-[132px] h-[56px] text-stylish-black text-normal font-semibold text-[21px] cursor-pointer whitespace-nowrap bg-white normal-case',
                                        )}
                                        style={{ borderRadius: 36 }}
                                        onClick={() => { setTutorialStep(2) }}
                                    >
                                        Great!
                                    </Button>
                                </motion.div>
                            </motion.div>
                        }
                    </motion.div>
                    </AnimatePresence>
                    :
                    <></>
            }

            <div className='flex flex-col justify-between items-center w-full h-full' style={appMode === AppMode.CONSTRUCTOR ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}>
                <NameplateCanvasComponent />
                <NameplateHeader />
                <div
                    className={cls('w-full h-full flex flex-col justify-end items-center', {
                        'z-[100]': appMode === AppMode.CONSTRUCTOR,
                    })}
                >
                    <motion.div
                        id={`${mobilePanel}`}
                        initial={false}
                        style={{ y: 290 }}
                        animate={appMode === AppMode.LANDING ? { x: 0, y: 290 } : { x: 0, y: 0 }}
                        transition={{ duration: 0, type: 'ease' }}
                        className={cls('max-w-md xl:max-w-lg w-full relative z-[1000]')}
                    >
                        <NameplateMenuActions />
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
};

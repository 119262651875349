import React from 'react';
import { useMediaQuery } from '@mui/material';

export const Overlay: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');

  if (!isMobile) {
    return (
      <div
        className='h-full w-[66%] fixed left-0 bottom-0 right-0 z-20 pointer-events-none'
        style={{
          background: 'linear-gradient(90deg, rgba(245,245,245,1) 45%, rgba(245,245,245,50) 55%, rgba(245,245,245,0.0625) 88%, rgba(245,245,245,0) 100%)',
        }}
      />
    );
  } else {
    return (
      <div
        className='h-[400px] w-full fixed left-0 bottom-0 right-0 z-20 pointer-events-none'
        style={{
          background:
            'linear-gradient(0deg, rgba(245,245,245,1) 30%, rgba(245,245,245,0) 100%, rgba(245,245,245,0) 100%)',
        }}
      />
    );
  }
};

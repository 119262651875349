import React, { useEffect, useRef, useState } from 'react';
import { ICanvasApp, PendantCanvasApp, PendantCanvasAppProps } from '@/pixi';
import { NameplateCanvasApp, NameplateCanvasAppProps } from '@/pixi/nameplate-canvas-app';
import { AppMode, useCommonState, usePinchZoom } from '@/hooks';
import { useMediaQuery } from '@mui/material';

type Props =
  | {
      App: typeof PendantCanvasApp;
      appProps: PendantCanvasAppProps;
    }
  | {
      App: typeof NameplateCanvasApp;
      appProps: NameplateCanvasAppProps;
    };

export const Canvas: React.FC<Props> = (props: Props) => {
  const { App } = props;
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [app, setApp] = useState<ICanvasApp | null>(null);
  const { pinchStart, pinchEnd } = usePinchZoom();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { appMode } = useCommonState();

  useEffect(() => {
    if (!parentRef.current) return;

    const newApp = new App(parentRef.current);
    newApp.update(props.appProps as any);
    setApp(newApp);

    return () => {
      newApp.destroy();
      setApp(null);
    };
  }, []);

  useEffect(() => {
    app?.update(props.appProps);
  }, [props.appProps]);


  useEffect(() => {
    if (isMobile && appMode === AppMode.CONSTRUCTOR) {
      pinchStart();
    }

    return () => {
      pinchEnd();
    }
  }, [appMode]);

  return <div ref={parentRef} style={{ position: 'absolute' }} />;
};

import React, { ForwardRefRenderFunction, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import LogoIcon from '../../../../assets/images/icons/common/logo.png';
import LogoText from '../../../../assets/images/icons/common/logo-text.png';
import { ANCHOR_TABS } from '../../enums';
import { ContactsDisclosure } from '../ContactsDisclosure';
import { useNavigate } from 'react-router-dom';
import { AppMode, useCommonState } from '@/hooks';
import { mobilePanel } from '@pages/nameplate/NameplateMobile/NameplateMobile';
import ReactGA from 'react-ga4'
import { usePrice } from '@/hooks/use-price';
import { MobileSubscriptionPopup } from '../MobileSubscriptionPopup';

export type MobileNavbarRef = {
  open: () => void;
  close: () => void;
  openState: boolean;
};

type Props = {
  [key: string]: any;
};

const MobileNavbarMenu: ForwardRefRenderFunction<MobileNavbarRef, Props> = (props, ref) => {
  const { onChangeAppMode } = useCommonState();
  const [isOpen, setIsOpen] = React.useState(false);
  const closeMenu = () => setIsOpen(false);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const variantsContent = {
    hidden: {
      x: -20,
      opacity: 0,
    },
    visible: (custom: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.3,
      },
    }),
  };

  const handleDiscountTap = () => {
    setIsShowPopup(true)
  }

  React.useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
      openState: isOpen,
    }),
    [],
  );

  useEffect(() => {
    const mobPanelEl = document.getElementById(mobilePanel);

    if (!mobPanelEl) {
      return;
    }

    const nativeZIndex = getComputedStyle(mobPanelEl).zIndex;

    const html = document.querySelector('html');
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      mobPanelEl.style.zIndex = '29';
      // if (html) {
      //   html.style.overflow = 'hidden';
      // }
    }
    return () => {
      document.body.style.overflow = 'visible';
      mobPanelEl.style.zIndex = nativeZIndex;
      // if (html) {
      //   html.style.overflow = 'auto';
      // }
    };
  }, [isOpen]);

  const { priceData } = usePrice();
  const handleOpenConstructor = () => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })
    setIsOpen(false)
    onChangeAppMode(AppMode.CONSTRUCTOR);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <AnimatePresence>
        {isOpen && (
          <div  style={{
            width: '100%', 
            height: '100svh',
            backdropFilter: 'blur(44px)',
          }}
          className='fixed bg-stylish-black/50 left-0 top-0'
        >
          <motion.div
            // initial={{ opacity: 0, scale: 0, originX: 'right', originY: 'top' }}
            // animate={{ opacity: 1, scale: 1, originX: 'right', originY: 'top' }}
            // exit={{ opacity: 0, scale: 0, originX: 'right', originY: 'top' }}
            // transition={{ type: 'spring', stiffness: 100, damping: 15 }}
            //  h-screen min-h-screen overflow-y-auto
            style={{
              width: 'full', 
              height: '95%',
            }}
            className='flex flex-col top-0 left-0 bg-white px-3 pb-10 pt-8 mt-3 ml-2 mr-2 mb-3 rounded-2xl'
          >
            {isShowPopup ?
              <div style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}>
                <MobileSubscriptionPopup onClose={() => { setIsShowPopup(false) }} />
              </div>
              :
              <></>
            }
            {/* <div className='flex justify-end'>
              <button className='bg-transparent outline-0 border-0' onClick={closeMenu}>
                <CloseIcon className='text-stylish-black' />
              </button>
            </div> */}

            <motion.div
              className='grow pl-6 text-stylish-black font-averta'
              initial={'hidden'}
              animate={'visible'}
            >
              <motion.div custom={1} variants={variantsContent} className='mb-1.5'>
                <img className='w-9 h-14 object-contain' src={LogoIcon} alt='logo' />
              </motion.div>

              <motion.div
                custom={1.3}
                variants={variantsContent}
                className={'w-[134px] h-[19px] mb-4'}
              >
                <img
                  className='h-auto max-w-full object-contain'
                  src={LogoText}
                  alt='two-in-town'
                />
              </motion.div>
              <motion.p variants={variantsContent} custom={1.6} className='leading-6 max-w-[239px]'>
                Crafting personalized gold and silver nameplates that celebrate your uniqueness
              </motion.p>
            </motion.div>

            <motion.ul
              initial={'hidden'}
              animate={'visible'}
              className='flex flex-col'
            >
              <motion.li
              custom={1.9} 
              variants={variantsContent}
               className='py-4 border-b-[1px] border-solid border-stylish-black/30 font-bold mx-6'>
                <a
                  onClick={closeMenu}
                  href={`#${ANCHOR_TABS.whyUs}`}
                  className='text-xl no-underline text-stylish-black  px-0'
                >
                  Why us?
                </a>
              </motion.li>
              <motion.li
              custom={2.2} 
              variants={variantsContent}
              className='py-4 border-b-[1px] border-solid border-stylish-black/30 font-bold mx-6'>
                <a
                  onClick={closeMenu}
                  href={`#${ANCHOR_TABS.howItWorks}`}
                  className='text-xl no-underline text-stylish-black px-0'
                >
                  How it works?
                </a>
              </motion.li>
              <motion.li
              custom={2.5} 
              variants={variantsContent}
              className='text-xl pb-8 text-stylish-black font-averta font-bold mx-6'>
                <ContactsDisclosure>
                  <div className='flex flex-col'>
                    <a
                      className='text-stylish-black no-underline font-averta text-lg font-bold mb-2'
                      href='tel:+12122780070'
                    >
                      (212) 278-0070
                    </a>
                    <a
                      className='text-stylish-black no-underline font-averta mb-2 font-normal'
                      href='mailto:support@twointown.com'
                    >
                      support@twointown.com
                    </a>
                    <p className='text-base w-[217px] leading-6 text-stylish-black font-normal'>
                      71 W 47th Street, <br />
                      Suite 502, New York, <br />
                      NY 10036
                    </p>
                  </div>
                </ContactsDisclosure>
              </motion.li>
            </motion.ul>
            <div className='flex justify-center items-center w-full h-[64px]'>
            <motion.button
              onClick={handleOpenConstructor}
              custom={2.6} 
              initial={'hidden'}
              animate={'visible'}
              variants={variantsContent}
              style={{borderRadius: 36, height: '64px', width: '300px'}}
              className='font-averta text-xl text-white font-bold bg-terracotta border-0 whitespace-nowrap'
            >
              Design your own
            </motion.button>
            </div>
            {/* <a onClick={handleDiscountTap} className='text-stylish-black text-center pt-5 font-semibold underline'>Subscribe and get 15% OFF</a> */}
          </motion.div>
          </div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

export default React.memo(React.forwardRef(MobileNavbarMenu));

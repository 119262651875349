import React, { useEffect, useState } from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as BgSVG } from '@assets/images/landing/svg/constructor-bg-element-1.svg';
import { ReactComponent as Bg2SVG } from '@assets/images/landing/svg/pattern-top-right1.svg';
import { ReactComponent as Bg3SVG } from '@assets/images/landing/svg/pattern-top-right2.svg';
import { AppMode, useCommonState } from '@/hooks';
import { NameplateMobileSquare } from '@pages/nameplate/NameplateMobile/NameplateMobileSquare';
import cls from 'classnames';




const variants = {
    openConstructor: { borderRadius: '0', height: '100svh', margin: '0px', y: -200, transition: { duration: 0.4 }, transitionEnd: {} },
    closeConstructor: { borderRadius: '1.5rem', height: '568px', margin: '10px', y: 0, transition: { duration: 0.3 } },
    showFullView: { opacity: 1, y: 0, transition: { duration: 0.3 }, transitionEnd: { display: 'block', y: 0 } },
    hideFullView: { opacity: 0, y: 0, transition: { duration: 0.4 }, transitionEnd: { display: 'block' } },
    showLips: { opacity: 1, transition: { duration: 0.3 }, transitionEnd: { display: 'block' } },
    hideLips: { opacity: 0, transition: { duration: 0.4 }, transitionEnd: { display: 'none' } }
};

export const MobileConstructor: React.FC = () => {

    const { appMode, onChangeAppMode } = useCommonState();

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOpenContructor = () => {
        if (appMode === AppMode.LANDING) {
            if (scrollPosition <= 250) {
                onChangeAppMode(AppMode.CONSTRUCTOR);
            } else {
                const cb = window.scrollTo(0, 0);
                setTimeout(function () { //Start the timer
                    onChangeAppMode(AppMode.CONSTRUCTOR);
                }.bind(this), scrollPosition + 10)
            }
        }
    };

    return (
        <>
            <div>
                <motion.section
                    initial={false}
                    whileInView='visible'
                    viewport={{ amount: 0.15, once: true }}
                    className='relative pb-30 pt-6 mt-20'
                    variants={variants}
                    animate={appMode === AppMode.CONSTRUCTOR ? 'hideFullView' : 'showFullView'}
                >
                    <Container
                        style={{
                            position: 'relative',
                        }}
                    >
                        <div className='hidden lg:block absolute left-0 bottom-[4rem]'>
                            <BgSVG />
                        </div>
                        <div className='flex flex-col lg:flex-row justify-between gap-x-4 items-center'>
                            <motion.div
                                className='lg:pl-6 lg:max-w-[29.3rem] w-full relative z-20'
                            >
                                <h1 className='font-averta font-extrabold pl-5 lg:pl-0 text-stylish-black text-5xl lg:text-[4rem] leading-10 lg:leading-[3.5rem] mb-4'>
                                    Customize <br /> in a tap!
                                </h1>
                                <p className='hidden lg:block text-stylish-black text-2xl mb-10'>
                                    Create your designs on flight
                                </p>
                            </motion.div>
                        </div>

                    </Container>
                </motion.section>

                <motion.div
                    initial={false}
                    variants={variants}
                    animate={!(appMode === AppMode.CONSTRUCTOR) ? 'showLips' : 'hideLips'}
                >
                    <Bg3SVG className='absolute right-[-54px] top-[100px] -z-20' width={'50%'} />
                </motion.div>
                <motion.div
                    onClick={handleOpenContructor}
                    variants={variants}
                    initial={false}
                    className='shadow-3xlwarmContMob'
                    animate={appMode === AppMode.CONSTRUCTOR ? 'openConstructor' : 'closeConstructor'}
                    style={{ overflow: 'hidden', position: 'relative', height: '568px', margin: 10, borderRadius: '1.5rem' }}
                >
                    <div
                        className={appMode === AppMode.CONSTRUCTOR ?
                            cls('w-full absolute overflow-hidden h-full')
                            :
                            cls('w-full absolute overflow-hidden h-full rounded-3xl')}
                    >
                        <NameplateMobileSquare />
                    </div>
                    <div style={{
                        position: 'absolute',
                        background:
                            'linear-gradient(0deg, rgba(245,245,245,1) 30%, rgba(245,245,245,0) 100%, rgba(245,245,245,0) 100%)',
                        width: '100%',
                        height: '400px',
                        bottom: 0
                    }}>
                    </div>
                    <motion.div
                        initial={false}
                        variants={variants}
                        animate={!(appMode === AppMode.CONSTRUCTOR) ? 'showLips' : 'hideLips'}
                    >
                        <Bg2SVG className='absolute top-[-35px] right-[11px]' width={'50%'} />
                    </motion.div>
                </motion.div>
            </div>
        </>
    );
};

import React, { useState } from 'react';

import { Canvas } from '../../components';

import { NameplateCanvasApp } from './NameplateCanvasApp';
import { useMediaQuery } from '@mui/material';

import {
  AppMode,
  useCommonState,
  useNameplateSettings,
  useResizeHandler,
} from '../../hooks';
import { BackgroundName } from './backgrounds';
import { NameplateSizeType } from '../../hooks/nameplate/use-nameplate-settings/types';

export const NameplateCanvasComponent = () => {
  const { rulerIsEnabled, rulerMode, zoom, appMode } = useCommonState();
  const {
    fontSize,
    letterSpacing,
    font,
    text,
    textDeformation,
    selectedMaterial,
    textSize,
    texturesLoading,
    chainSize,
    onChangeText,
    onChangeFontSize,
    onChangeArea,
    onChangeWidthInMM,
    onChangeHeightInMM,
    onChangeTexturesLoading,
    onChangeSizeLimitState,
    saveSnapshot,
  } = useNameplateSettings();
  const canvasPosition = useResizeHandler();
  const isMobile = useMediaQuery('(max-width: 1023px)')
    const [maxWidth, setMaxWidth] = useState(58);

  const backgroundSelection = () => {
    const isMobile = useMediaQuery('(max-width: 1023px)')

    return isMobile ? BackgroundName.Model4 : BackgroundName.Model4;
  };

  return (
    <Canvas
      App={NameplateCanvasApp}
      appProps={{
        appModeIsContructor: appMode === AppMode.CONSTRUCTOR,
        position: canvasPosition,
        backgroundName: backgroundSelection(),
        pendantSize: NameplateSizeType.SMALL,
        zoom,
        rulerIsEnabled,
        rulerMode,
        isMobile,
        label: {
          text,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontFamily: font,
          deformation: textDeformation,
          textSize,
          fontSize: Number(fontSize),
          letterSpacing,
          materialType: selectedMaterial,
          sizeLimitMm: maxWidth,
        },
        texturesLoading,
        chainSize,
        saveSnapshot,
        setFontSize: onChangeFontSize,
        setText: onChangeText,
        setLabelArea: onChangeArea,
        setLabelWidth: onChangeWidthInMM,
        setLabelHeight: onChangeHeightInMM,
        setTexturesLoading: onChangeTexturesLoading,
        setNameplateSizeLimitState: onChangeSizeLimitState,
      }}
    />
  );
};

import React from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

import { getMaterialNumber, getWeightInGrams } from '@/utils/nameplate';
import { getPriceMessage } from '@hooks/nameplate/use-nameplate-settings/messages';
import { preciseRound } from '@/utils/math';
import { getPrice } from '@/api';

import { useCommonState } from '@hooks/use-common-state';
import { useShippingData } from '@hooks/use-shipping';
import { useNameplateSettings } from '@hooks/nameplate/use-nameplate-settings';


import { PriceStateContext } from './types';
import { PriceDto } from '@api/cms/shipping/dto';
import {
  NameplateSizeLimitState,
  NameplateMaterialType,
  DEFAULT_PRICE
} from '@/hooks';

import { APP_LINK_CHECKOUT_PAGE } from '@/constants/common';
import ReactGA from 'react-ga4'

export const PriceState = React.createContext({} as PriceStateContext);

let debounceTimer: any;

export const PriceStateContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [isFetching, setFetching] = React.useState<boolean>(true);
  const [state, setState] = React.useState<null | PriceDto>(null);
  const [error, setError] = React.useState<null | AxiosError>(null);

  const { pathname } = useLocation();

  const { callMessage } = useCommonState();
  const { shippingData, onShippingData } = useShippingData();
  const {
    texturesLoading,
    constructor,
    images,
    fontSize,
    sizeLimitState,
    price,
    chainSize,
    onSetPrice,
    text,
    area,
    selectedMaterial
  } = useNameplateSettings();

  const getPendantPrice = async ({
    pendantText,
    pendantArea,
    pendantMaterial,
    pendantChainSize,
  }: {
    pendantText: string;
    pendantArea: number;
    pendantMaterial: NameplateMaterialType;
    pendantChainSize: number;
  }) => {
    if (pendantText === '') {
      onSetPrice(0);
      return false;
    }

    const weight = preciseRound(getWeightInGrams(pendantArea, pendantMaterial));
    const material = getMaterialNumber(pendantMaterial);

    try {
      setFetching(true);

      const res = await getPrice({
        weight,
        material,
        quantity: shippingData.quantity,
        chainLength: pendantChainSize,
        ...(shippingData.selectedDelivery && {
          rateId: shippingData.selectedDelivery.object_id,
        }),
      });

      if (res) {
        setState(res);

        onShippingData({
          ratePrice: res.ratePrice,
          total: res.total,
        });

        onSetPrice(res.singleProductPrice);

        if (price.data !== DEFAULT_PRICE && sizeLimitState === NameplateSizeLimitState.Allowed) {
          if (pathname === APP_LINK_CHECKOUT_PAGE) {
            callMessage(getPriceMessage(res.total));
          } else {
            if (shippingData.total != res.total) {
              callMessage(getPriceMessage(res.singleProductPrice));
            }
          }
        }
      }
    } catch (err) {
      const errTyped = err as AxiosError;
      setError(errTyped);
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    const debouncedGetPrice = () => {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(() => {
        getPendantPrice({
          pendantArea: area,
          pendantText: text,
          pendantMaterial: selectedMaterial,
          pendantChainSize: chainSize,
        });
      }, 1000);
    };

    if (!texturesLoading && !constructor?.isLoading && !images?.isLoading) {
      debouncedGetPrice();
    }
  }, [
    // for init
    texturesLoading,
    constructor?.isLoading,
    images?.isLoading,

    // check changes
    area,
    fontSize,
    chainSize,
    sizeLimitState,
    selectedMaterial,
    shippingData?.selectedDelivery?.object_id,
    shippingData?.id,
  ]);

  return (
    <PriceState.Provider
      value={{
        priceData: state,
        isLoading: isFetching,
        error: error,
      }}
    >
      {children}
    </PriceState.Provider>
  );
};

export const usePrice = () => {
  const context = React.useContext(PriceState);

  if (!context) {
    throw Error("usePrice has to uses inside 'PriceStateContextProvider' provider");
  }

  return context;
};

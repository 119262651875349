import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

const getPosition = (isMobile: boolean) => {
  const position = { x: 0, y: 0 };

  // if (isMobile) {
  //   position.x = window.innerWidth * 0.5;
  //   position.y = (window.innerHeight - 370 + 100) / 2;
  // } else {
  //   position.x = (window.innerWidth + 200) / 2;
  //   position.y = window.innerHeight * 0.45;
  // }

  if (isMobile) {
    position.x = window.innerWidth * 0.5;
    position.y = 200;//(window.innerHeight - 370 + 100) / 2;
  } else {
    position.x = window.innerWidth*2/3 //(window.innerWidth + 400) / 2;
    position.y = window.innerHeight * 0.45;
    // position.x = -1000
    // position.y = window.innerHeight*0.45
  }

  return position;
};

export const useResizeHandler = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [canvasPosition, setCanvasPosition] = useState(getPosition(isMobile));

  useEffect(() => {
    const handleResize = () => {
      setCanvasPosition(() => getPosition(isMobile));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return canvasPosition;
};

import { Constructor } from './constructor';

export enum NameplateMaterialType {
  GOLD = 'Gold',
  ROSE_GOLD = 'Rose_gold',
  SILVER = 'Silver',
}

export function getNameplateMaterialCarat(material: string) {
  switch (material) {
    case 'gold': return '14k'
    case 'rose_gold': return '14k'
    case 'silver': return '925'
  }
}

export enum NameplateSizeType {
  SMALL = 4.75,
  MEDIUM = 5.75,
  LARGE = 6.75,
}

export enum NameplateSizeLimitState {
  Allowed = 'Allowed',
  Warn = 'Warn',
  Exceeded = 'Exceeded',
  Empty = 'Empty',
}

export type NameplateImages = {
  preview: string | undefined;
  previewWithoutChain: string | undefined;
  engraving: string | undefined;
  pngEngraving: string | undefined;
  isLoading: boolean;
};

export type State = {
  price: {
    data: number;
    isLoading: boolean;
  };
  area: number;
  selectedMaterial: NameplateMaterialType;
  text: string;
  font: string;
  fontWeight: number | string;
  fontStyle: string;
  fontSize: number;
  letterSpacing: number;
  textDeformation: number;
  textSize: number;
  texturesLoading: boolean;
  sizeLimitState: NameplateSizeLimitState;
  chainType: string;
  chainSize: number;
  widthInMM: number;
  heightInMM: number;
  isSnapshot: boolean;
  snapshotIndex: number;
  snapshots: State[];
};

export interface ContextType {
  resetState: () => void;
  state: State;
  price: {
    data: number;
    isLoading: boolean;
  };
  area: number;
  selectedMaterial: NameplateMaterialType;
  text: string;
  font: string;
  fontWeight: number | string;
  fontStyle: string;
  fontSize: number;
  letterSpacing: number;
  textDeformation: number;
  textSize: number;
  texturesLoading: boolean;
  sizeLimitState: NameplateSizeLimitState;
  chainType: string;
  chainSize: number;
  widthInMM: number;
  heightInMM: number;
  clearState: () => void;
  onSetPrice: (cost: number) => void;
  onChangeArea: (area: number) => void;
  onSelectMaterial: (material: NameplateMaterialType) => void;
  onChangeText: (text: string) => void;
  onChangeFont: (font: string) => void;
  onChangeFontWeight: (fontWeight: string) => void;
  onChangeFontStyle: (fontStyle: string) => void;
  onChangeFontSize: (size: number) => void;
  onChangeLetterSpacing: (space: number) => void;
  onChangeTextDeformation: (deformation: number) => void;
  onChangeTextSize: (size: number) => void;
  onChangeTexturesLoading: (isLoading: boolean) => void;
  onChangeSizeLimitState: (limitState: NameplateSizeLimitState) => void;
  onChangeChainType: (chainType: string) => void;
  onChangeChainSize: (chainSize: number) => void;
  onChangeWidthInMM: (width: number) => void;
  onChangeHeightInMM: (height: number) => void;
  onUndo: () => void;
  onRedo: () => void;
  isSnapshot: boolean;
  patchSnapshot: () => void;
  saveSnapshot: () => void;
  // constructor
  constructor: {
    config: Constructor;
    isLoading: boolean;
  };
  images: NameplateImages | null;
  exportImages: () => void;
  saveImages: (engraving: string, preview: string, previewWithoutChain: string, pngEngraving: string) => void;
}

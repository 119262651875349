import React from 'react';
import { CloseButton } from '@/components/UI/buttons/CloseButton';
import { AppMode, useCommonState } from '@/hooks';
import { UndoRedoButtons } from '@/components/nameplate/page/UndoRedoButtons';
import { RulerButtons } from '@/components/UI/buttons/RulerButtons';
import { ZoomButton } from '@/components/UI/buttons/ZoomButton';
import { useMediaQuery } from '@mui/material';
import { MobileOrderButton } from '@/components/nameplate/page/MobileOrderButton';
import { useNavigate } from 'react-router-dom';


const NameplateHeader: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { appMode } = useCommonState();
  const isLanding = appMode === AppMode.LANDING;
  const navigate = useNavigate();

  const handleGoToCheckoutProcess = () => {
    navigate("/checkout");
  };
  
  return appMode === AppMode.CONSTRUCTOR ? (
    <>
      {!isMobile ? <CloseButton /> : <ZoomButton />}
      {isMobile && (
        <div className={
          window.innerWidth < 390 ? 
          'flex justify-start items-start gap-1 fixed top-4 right-2 z-[1001]' 
          : 
          'flex justify-start items-start gap-1 fixed top-4 right-4 z-[1001]'
          }>
          <UndoRedoButtons whiteButtons={true} />
          <RulerButtons whiteButtons={true} />
        {!isLanding && <MobileOrderButton isStatic={true} onClick={handleGoToCheckoutProcess}/>}
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default NameplateHeader;

import React from 'react';
import cls from 'classnames';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/common/cross-white.svg';
import { Button } from '../Button';
import { AppMode, NameplateMenuPages, useCommonState, useNamePlateMenu } from '@/hooks';
import { useMediaQuery } from '@mui/material';

export const CloseButton: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { onChangeAppMode } = useCommonState();
  const { onSelectMenuPage } = useNamePlateMenu();
  const { onChangeZoom, onChangeRulerIsEnabled, rulerIsEnabled } = useCommonState();


  const handleOnClick = () => {
    if (rulerIsEnabled) {
      onChangeRulerIsEnabled(false);
    }
    onChangeZoom(0.15);
    onChangeAppMode(AppMode.LANDING);
    onSelectMenuPage(NameplateMenuPages.MENU);
  };

  return (
    <div onClick={handleOnClick} className={cls('fixed cursor-pointer', !isMobile ? 'top-8 right-8' : 'top-4 right-4')}>
      <Button
        isTransparent={true}
        style={{
          width: !isMobile ? '64px' : '56px',
          height: !isMobile ? '64px' : '56px'
        }}
      >
        <CrossIcon />
      </Button>
    </div>
  );
};

import { useCommonState } from '@/hooks';
import Hammer from 'hammerjs';

const zoomInSensitivity = 0.02;
const zoomOutSensitivity = 0.04;

const minAccumulatedScale = 0.1;
const maxAccumulatedScale = 2.5;

export const usePinchZoom = () => {
  const { zoom, onChangeZoom } = useCommonState();

  let accumulatedScale = 1;

  const handlePinchZoom = (scale: number) => {
    const newScale =
        scale >= 1
          ? accumulatedScale * (1 + (scale - 1) * zoomInSensitivity)
          : accumulatedScale * (1 + (scale - 1) * zoomOutSensitivity);

    accumulatedScale = Math.min(Math.max(newScale, minAccumulatedScale), maxAccumulatedScale);

    const newZoom = zoom * accumulatedScale;
    const clampedZoom = Math.min(Math.max(newZoom, 0.1), 0.6);

    onChangeZoom(clampedZoom);
  };

  const hammer = new Hammer(document.body);

  const pinchStart = () => {
    hammer.get('pinch').set({ enable: true });

    hammer.on('pinchmove', (e) => {
      if (e.type === 'pinchmove') {
        handlePinchZoom(e.scale);
      }
    });
  }

  const pinchEnd = () => {
    hammer.off('pinchmove');
  };

  return {
    pinchStart,
    pinchEnd
  }
}
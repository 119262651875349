import React, { useEffect } from 'react';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import cls from 'classnames';
import { useMediaQuery } from '@mui/material';

import { usd } from '@/utils/format';
import { usePrice } from '@/hooks/use-price';
import { useCommonState, useNameplateSettings } from '@/hooks';

import { NameplateSizeLimitState } from '@/hooks/nameplate/use-nameplate-settings/types';
import ReactGA from 'react-ga4'

interface Props {
  isStatic?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(Button)`
  ${({ theme }) => `
    transition: ${theme.transitions.create(['background-color', 'transform'], {
      duration: theme.transitions.duration.standard,
    })};
    &:hover {
      transform: scale(1.1) !important;
    }
  `}
`;

export const MobileOrderButton: React.FC<Props> = ({ isStatic, onClick = () => {} }) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { priceData, isLoading } = usePrice();
  const { onChangeOrderAvailable } = useCommonState();
  const { text, sizeLimitState, exportImages } = useNameplateSettings();

  const [isBtnDisabled, setIsBtnDisabled] = React.useState<boolean>(!(text.length > 0));

  React.useEffect(() => {
    onChangeOrderAvailable(text.length > 0);
    setIsBtnDisabled(!(text.length > 0));
  }, [text]);

  useEffect(() => {
    ReactGA.gtag("event","view_item", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })

  }, [priceData])

  const handleOnClick = async () => {
    ReactGA.gtag("event","add_to_cart", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })
    
    // Send a custom event
    ReactGA.event({
      category: "user_flow",
      action: "click_get_it",
      label: "Click order button", // optional
    });
    ReactGA.send({ hitType: "order_summary", page: "/order-summary", title: "Order Summary" });

    await exportImages();
    onClick();
  };

  const isButtonVisible = priceData?.singleProductPrice && priceData.singleProductPrice !== 3457.93;

  if (!isButtonVisible) return null;

  const BtnComponent = isMobile ? Button : StyledButton;

  const sizeLimitError = sizeLimitState === NameplateSizeLimitState.Exceeded;

  function getDecimalPart(number: number) {
    return parseFloat(number.toFixed(2)).toString().split('.')[1]; // Adjust as needed for desired precision
  }
  
  return (
    <Grow in timeout={500}>
      <BtnComponent
        className={cls(
          'w-[340px] px-4 py-4 rounded-3xl text-white text-bold mb-4 font-bold text-[17px] cursor-pointer whitespace-nowrap bg-stylish-black normal-case',
          {
            'mt-0 mb-4': isStatic && isMobile,
            'absolute bottom-20 right-20': !isStatic || !isMobile,
            'bg-stylish-black/25': isBtnDisabled || sizeLimitError,
          },
        )}
        disabled={isBtnDisabled || sizeLimitError || isLoading}
        onClick={handleOnClick}
        style={isStatic ? { width: '100%', height: '48px' } : {}}
      >
        {/* {sizeLimitError
          ? 'Order'
          : `Order ${usd.format(priceData.singleProductPrice)}`
        } */}

        <p>Order ${Math.floor(priceData.singleProductPrice)}</p>
        <p className='text-[10px] pb-2'>{getDecimalPart(priceData.singleProductPrice)}</p>
      </BtnComponent>
    </Grow>
  );
};

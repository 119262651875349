import * as PIXI from 'pixi.js';
import { NameplateCanvasApp, NameplateCanvasAppProps } from './NameplateCanvasApp';
import { NameplateCanvasAppTextureByName } from './NameplateCanvasAppAssetsLoader';
import { MobileController } from '../common/controllers/MobileController';
import { NameplateLabel } from './NameplateLabel';
import { NameplateRuler } from './NameplateRuler';
import { DesktopController } from '../common/controllers/DesktopController';
import { IController } from '../common/controllers/types';

import { NameplateChain } from './NameplateChain';
import { ShadowContainer, SecondShadowContainer } from './ShadowContainer';
import { Background } from './Background';
import { backgroundConfigByName, ChainSide, getPendantProps } from './backgrounds';
import { NameplateSizeLimits } from './NameplateSizeLimits';

export const NAMEPLATE_TO_PENDANT_SCALE = 0.33;

export class NameplateCanvasAppLayout {
  private readonly app: NameplateCanvasApp;
  public props: NameplateCanvasAppProps | null = null;
  public controller!: IController;

  public readonly bg: Background;
  public readonly labelContainer: PIXI.Container;
  public readonly label: NameplateLabel;
  public readonly transformViewContainer: PIXI.Container;
  public readonly chainLeft: NameplateChain;
  public readonly chainRight: NameplateChain;
  public readonly chainsRingLeft: PIXI.Sprite;
  public readonly chainsRingRight: PIXI.Sprite;
  public readonly ruler: NameplateRuler;
  public readonly pendantContainer: PIXI.Container;
  public readonly chainsContainer: PIXI.Container;
  public readonly shadowContainer: ShadowContainer;
  public readonly secondShadowContainer: SecondShadowContainer;
  public readonly sizeLimits: NameplateSizeLimits;

  constructor(app: NameplateCanvasApp) {
    this.app = app;

    this.bg = new Background();
    app.stage.addChild(this.bg.root);

    this.pendantContainer = new PIXI.Container();


    app.stage.addChild(this.pendantContainer);

    this.chainsContainer = new PIXI.Container();
    this.pendantContainer.addChild(this.chainsContainer);

    this.chainLeft = new NameplateChain({
      chainsContainer: this.chainsContainer,
      side: ChainSide.ChainLeft,
    });
    this.chainRight = new NameplateChain({
      chainsContainer: this.chainsContainer,
      side: ChainSide.ChainRight,
    });

    this.shadowContainer = new ShadowContainer();

    this.secondShadowContainer = new SecondShadowContainer();
    this.pendantContainer.addChild(this.secondShadowContainer);

    this.labelContainer = new PIXI.Container();
    this.shadowContainer.addChild(this.labelContainer);
    this.secondShadowContainer.addChild(this.shadowContainer)

    this.label = new NameplateLabel(this.app, this);
    this.labelContainer.addChild(this.label.root);

    this.sizeLimits = new NameplateSizeLimits({ layout: this });
    app.stage.addChild(this.sizeLimits.root);

    this.ruler = new NameplateRuler();
    app.stage.addChild(this.ruler.root);

    this.transformViewContainer = new PIXI.Container();
    app.stage.addChild(this.transformViewContainer);

    this.chainsRingLeft = new PIXI.Sprite();
    this.pendantContainer.addChild(this.chainsRingLeft);

    this.chainsRingRight = new PIXI.Sprite();
    this.pendantContainer.addChild(this.chainsRingRight);
  }

  ensureControllerCreated(isMobile: boolean) {
    if (this.controller) return;

    this.controller = new (isMobile ? MobileController : DesktopController)({
      app: this.app,
      hammer: this.app.hammer,
      panningCallback: this.controllerPanningCallback,
      pointerUpCallback: this.controllerPointerUpCallback,
      parent: this.transformViewContainer,
      deactivateCallback: () => void 0,
    });
    this.controller.disablePan();
    this.controller.disableRotation();
    this.label.addGestureHandler();
  }

  render(props: NameplateCanvasAppProps, textureByName: NameplateCanvasAppTextureByName) {
    this.ensureControllerCreated(props.isMobile);

    const { backgroundName } = props;
    const { materialTexture } = textureByName;
    const {
      bg,
      controller,
      labelContainer,
      pendantContainer,
      label,
      ruler,
      chainLeft,
      chainRight,
      sizeLimits,
      chainsRingLeft,
      chainsRingRight,
    } = this;
    const prev = this.props;
    this.props = props;

    const bgConfig = backgroundConfigByName[backgroundName];
    bg.render(props);

    pendantContainer.position.copyFrom(bg.root.position);
    pendantContainer.scale.copyFrom(bg.root.scale);

    const pendantPropsBySize = getPendantProps(props.chainSize);
    labelContainer.x = bgConfig[pendantPropsBySize].x;
    labelContainer.y = bgConfig[pendantPropsBySize].y;
    labelContainer.scale.set(bgConfig[pendantPropsBySize].scale * NAMEPLATE_TO_PENDANT_SCALE);

    label.render(props.label, materialTexture);
    ruler.render(props, label, bgConfig.rulerColor);
    controller.render(props);

    chainLeft.setChain(bgConfig, label.circleLeft, props.label.materialType, textureByName);
    chainRight.setChain(bgConfig, label.circleRight, props.label.materialType, textureByName);

    chainsRingLeft.texture = textureByName.chainRingTexture;
    chainsRingLeft.x = chainLeft.chain.x - 8;
    chainsRingLeft.y = chainLeft.chain.y - 10;
    chainsRingLeft.angle = -11;
    chainsRingLeft.width = 10;
    chainsRingLeft.height = 25;

    chainsRingRight.texture = textureByName.chainRingTexture;
    chainsRingRight.x = chainRight.chain.x + 3;
    chainsRingRight.y = chainRight.chain.y - 12;
    chainsRingRight.angle = 40;
    chainsRingRight.width = 10;
    chainsRingRight.height = 25;
    sizeLimits.render(props);
  }


  private readonly controllerPanningCallback = () => {
    //
  };

  private readonly controllerPointerUpCallback = () => {
    //
  };

  setLabelArea(value: number) {
    this.props?.setLabelArea(value);
  }

  setLabelWidth(value: number) {
    this.props?.setLabelWidth(value);
  }

  setLabelHeight(value: number) {
    this.props?.setLabelHeight(value);
  }
}

import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import classes from './TextEditor.module.css';
import { useMediaQuery } from '@mui/material';

import { NameplateSizeLimitState, useNameplateSettings } from '@/hooks';
import { TextInput } from '../../../../UI/inputs/TextInput';

import fonts from '../fonts';

export const TextEditor: React.FC<{ defaultValueTextEditor?: string; defaultFont?: string }> = ({
  defaultValueTextEditor,
  defaultFont,
}) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { text, onChangeText, font, onChangeFont, sizeLimitState } = useNameplateSettings();

  const editorRef = React.useRef<HTMLDivElement>(null);
  const [defaultValue, setDefaultValue] = useState(text);

  useEffect(() => {
    setDefaultValue(text);
  }, [text]);

  const handleSetFont = (font: string) => {
    onChangeFont(font);
  };


  const handleInputClick = () =>{

    if(isMobile){
      setTimeout(() => {
        window.focus();
        window.scrollTo({top: 90});
      }, 50)
    }

  }

  const handleChangeText = (text: string) => {

    // if(isMobile){
    //   scrollTo({top: 0})
    // }

    if (typeof defaultValueTextEditor === 'undefined') {
      onChangeText(text);
    }
  };

  const isLimited = sizeLimitState === NameplateSizeLimitState.Exceeded;

  return (
    <>
      <div
        ref={editorRef}
        className={
          classes.textEditor +
          'w-full pb-0 flex flex-col justify-between items-center' +
          (!isMobile ? ' relative overflow-hidden' : '')
        }
        style={!isMobile ? { borderTopLeftRadius: 0 } : {}}
      >
        <div className={'w-[full]'}>
          <div className='flex justify-center items-center w-full'>
            <TextInput
              defaultValue={defaultValueTextEditor ?? defaultValue}
              noSpaces={true}
              onlyLetters={true}
              placeholder={'Type your name'}
              isLimited={isLimited}
              onChange={handleChangeText}
              onClick={handleInputClick}
            />
          </div>
          <div className='flex justify-center'>
            {!isMobile ? (
              <div className='w-[346px] h-px bg-stylish-black opacity-20 mb-4' />
            ) : (
              <div className='w-[346px] h-[1px] mb-0' />
            )}
          </div>
          { !isMobile ? (
          <div className={classes.title + ' z-20 self-center flex justify-center'}>
            <span>Style </span>
          </div>
          ) : (<></>)
          }
          <div
            className={cls(
              'flex w-full justify-center items-center',
              !isMobile ? 'pt-0 h-[82px]' : 'pt-0 h-[70px] pr-0.5',
            )}
          >
            {fonts.map((f, idx) => (
              <div
                className={cls(
                  classes.styleItem,
                  (defaultFont ?? font) === f.value ? classes.styleItemActive : '',
                )}
                key={idx}
                style={{ fontFamily: f.value }}
                onClick={() => handleSetFont(f.value)}
              >
                N
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import classes from './Menu.module.css';

import { NameplateMenuPages, getNameplateMaterialCarat, useNamePlateMenu, useNameplateSettings } from '../../../../../hooks';
import { NameplateSizeType } from '../../../../../hooks';

import { ReactComponent as GoldMaterialIcon } from '../../../../../assets/images/icons/materials/Gold.svg';
import { ReactComponent as RoseGoldMaterialIcon } from '../../../../../assets/images/icons/materials/Rose_gold.svg';
import { ReactComponent as SilverMaterialIcon } from '../../../../../assets/images/icons/materials/Silver.svg';
import { ReactComponent as SizeIcon } from '../../../../../assets/images/icons/common/size.svg';
import { ReactComponent as ChainIcon } from '../../../../../assets/images/icons/common/chainMenu.svg';

const materialIcons: { [index: string]: any } = {
  Gold: <GoldMaterialIcon className='w-[32px]'/>,
  Rose_gold: <RoseGoldMaterialIcon className='w-[32px]'/>,
  Silver: <SilverMaterialIcon className='w-[32px]'/>,
};

export const Menu: React.FC = () => {
  const { menuPage, onSelectMenuPage } = useNamePlateMenu();
  const { selectedMaterial, fontSize, chainSize } = useNameplateSettings();

  const smallChosen = fontSize == NameplateSizeType.SMALL || fontSize < NameplateSizeType.SMALL + (NameplateSizeType.MEDIUM - NameplateSizeType.SMALL) / 2;
  const mediumChosen = fontSize == NameplateSizeType.MEDIUM || (fontSize >= NameplateSizeType.MEDIUM / 2 && fontSize <= NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2);
  const largeChosen = fontSize == NameplateSizeType.LARGE || fontSize > NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2;

  const handleSelectMaterialPage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.MATERIAL);
  }, [menuPage]);

  const handleSelectSizePage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.SIZE);
  }, [menuPage]);

  const handleSelectChainPage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.CHAIN);
  }, [menuPage]);

  const getSizeTitle = () => {
    let res = '';

    if (smallChosen) {
      res = 'S';
    } else if (mediumChosen) {
      res = 'M';
    } else if (largeChosen) {
      res = 'L'
    }

    return 'Size: ' + res;
  }

  return (
    <div className='flex justify-between items-center w-full p-4 pt-2 pb-4 w-[335px]'>
      <div className={classes.menuItem} onClick={handleSelectMaterialPage}>
        <button className={classes.styleItem}>
          {materialIcons[selectedMaterial]}
        </button>
        <span className={classes.menuItemValue + ' mt-2'}>
        {getNameplateMaterialCarat(selectedMaterial.toLowerCase()) + " "} 
        {(selectedMaterial === 'Rose_gold' ? 'White_gold' : selectedMaterial).replace(/_/g, ' ')}
        </span>
      </div>
      <div className={classes.menuItem} onClick={handleSelectSizePage}>
        <button className={classes.styleItem}>
          <SizeIcon/>
        </button>
        <span className={classes.menuItemValue + ' mt-2'}>
          {getSizeTitle()}
        </span>
      </div>
      <div className={classes.menuItem} onClick={handleSelectChainPage}>
        <button className={classes.styleItem}>
          <ChainIcon />
        </button>
        <span className={classes.menuItemValue + ' mt-2'}>
          {chainSize == 16 && 'Chain: 16’’'}
          {chainSize == 18 && 'Chain: 18’’'}
        </span>
      </div>
    </div>
  );
};
